import TabPageLayout from '../../../hocs/AppPageLayout';
import EditChoicesGroupTabs from '../components/EditChoicesGroupTabs';
import EditChoiceGroupView from '../views/EditChoiceGroupView';

const breadCrumbs = [
  {
    title: 'Menu',
    link: '/menu',
  },
  {
    title: 'Choices',
    link: '/menu/choices',
  },
  {
    title: 'Edit Choices',
    link: '/menu/choices/edit',
  },
];

const EditChoices = () => {
  return (
    <TabPageLayout
      title="Edit Choices"
      TabComponent={EditChoicesGroupTabs}
      ContentComponent={EditChoiceGroupView}
      breadCrumbs={breadCrumbs}
      titleVariant="h5"
    />
  );
};

export default EditChoices;
