import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import AppButton from '../../../components/AppButton';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppCurrencyInput from '../../../components/AppCurrencyInput';
import AppEmptyTable from '../../../components/AppEmptyTable';
import AppRadioGroup from '../../../components/AppRadioGroup';
import AppSelector from '../../../components/AppSelector';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTable from '../../../components/AppTable';
import { IAppTableRow } from '../../../components/AppTable/types/table.types';
import AppTextInput from '../../../components/AppTextInput';
import HashScroll from '../../../hocs/HashScroll';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { decimalCalculations } from '../../../utils';
import { reorder } from '../../../utils/dnd.utils';
import EditChoicePopup, {
  IEditChoiceOnForm,
} from '../components/EditChoicePopup';
import { ACTION_KEYS, CHOICE_ITEMS_COLUMNS } from '../constants';
import { createChoiceGroupData } from '../redux/choiceGroupSlice';
import { setMenuPaginationSearchKey } from '../redux/menuPaginationSlice';
import { IChoice, IChoiceTable } from '../types/choice-groups.types';
import { ENTITY_STATUS } from '../types/common.types';
import { getFormattedChoiceItems } from '../utils/formatting.utils';

interface IChoiceGroupsForm {
  name: string;
  description?: string;
  status: string;
  minQty?: number;
  maxQty?: number;
  isRequired: boolean;
}

interface IChoiceItemForm {
  name: string;
  price?: number;
}

const choiceItemValidationSchema: yup.ObjectSchema<IChoiceItemForm> =
  yup.object({
    name: yup
      .string()
      .max(60, 'Character limit exceeded (60)')
      .required('Enter a valid choice name'),
    price: yup
      .mixed({ type: 'string|number' })
      .optional()
      .transform((value) => {
        if (!value) {
          return undefined;
        }
        return parseFloat(String(value).replace(/,/g, ''));
      }) as unknown as yup.ISchema<number>,
  });

const CreateChoiceGroupView = () => {
  const { restaurant } = useAppSelector(selectAuth);
  const { openSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const priceInputRef = useRef<HTMLInputElement>();
  const { openConfirmationPopup } = useConfirmationPopup();

  const [choices, setChoices] = useState<IChoice[]>([]);
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState<number | null>(
    null,
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    dispatch(setMenuPaginationSearchKey(''));
  }, [dispatch]);

  const qtyArray = Array.from({ length: choices.length }, (_, i) => i + 1);

  const choiceGroupValidationSchema: yup.ObjectSchema<IChoiceGroupsForm> =
    yup.object({
      name: yup
        .string()
        .max(60, 'Character limit exceeded (60)')
        .required('Group name is required. Enter a valid name'),
      description: yup
        .string()
        .max(256, 'Character limit exceeded (256)')
        .optional(),
      status: yup
        .string()
        .oneOf(Object.values(ENTITY_STATUS))
        .required('Please select a status.'),
      isRequired: yup.boolean().required('Please select one'),
      minQty: yup.number().when('isRequired', {
        is: true,
        then: (schema) =>
          schema
            .required()
            .oneOf(qtyArray, 'Quantity is invalid')
            .test(
              'is-gte',
              'Minimum choices must be less than or equal to maximum choices',
              (value, { parent }) => {
                return value <= parent.maxQty;
              },
            ),
        otherwise: (schema) => schema.optional(),
      }),

      maxQty: yup
        .number()
        .required()
        .oneOf(qtyArray, 'Quantity is invalid')
        .positive('Quantity must be a number')
        .test(
          'is-gte',
          'Maximum choices must be greater than or equal to minimum choices',
          (value, { parent }) => {
            return value >= parent.minQty;
          },
        ),
    });

  const formattedChoiceItems = useMemo(() => {
    return choices.map((choice) =>
      getFormattedChoiceItems(choice, restaurant?.posCurrencySymbol ?? ''),
    );
  }, [choices, restaurant?.posCurrencySymbol]);

  const handleOnCancel = () => {
    navigate(`/menu/choices`);
  };

  const handleOnClickAction = (
    key: string,
    data: IAppTableRow<IChoiceTable>,
  ) => {
    const updatedChoices = choices.map((choice) => {
      if (choice._id === data.key) {
        switch (key) {
          case ACTION_KEYS.AVAILABLE:
            return { ...choice, status: ENTITY_STATUS.AVAILABLE };
          case ACTION_KEYS.HIDE:
            openConfirmationPopup({
              heading: `Hide ${choice?.name}`,
              content: (
                <Box>
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    {choice?.name}
                  </Typography>
                  {' will be hidden from the choice'}
                </Box>
              ),
              confirmButtonTitle: 'Hide',
              onConfirm: () => {
                const updatedChoice = {
                  ...choice,
                  status: ENTITY_STATUS.HIDDEN,
                };
                setChoices((prevChoices) =>
                  prevChoices.map((prevChoice) =>
                    prevChoice._id === data.key ? updatedChoice : prevChoice,
                  ),
                );
              },
            });
            return choice;
          case ACTION_KEYS.UNAVAILABLE:
            openConfirmationPopup({
              heading: `Unavailable ${choice?.name}`,
              content: (
                <Box>
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    {choice?.name}
                  </Typography>
                  {
                    ' will be Unavailable and will no longer have access in the choice items'
                  }
                </Box>
              ),
              confirmButtonTitle: 'Unavailable',
              onConfirm: () => {
                const updatedChoice = {
                  ...choice,
                  status: ENTITY_STATUS.UNAVAILABLE,
                };
                setChoices((prevChoices) =>
                  prevChoices.map((prevChoice) =>
                    prevChoice._id === data.key ? updatedChoice : prevChoice,
                  ),
                );
              },
            });
            return choice;
          case ACTION_KEYS.DELETE:
            openConfirmationPopup({
              color: 'error',
              heading: `Delete ${choice?.name}`,
              content: (
                <Box>
                  <Typography component="span" sx={{ fontWeight: 600 }}>
                    {choice?.name}
                  </Typography>
                  {
                    ' will no longer be in the choice will be deleted permanently'
                  }
                </Box>
              ),
              confirmButtonTitle: 'Delete',
              onConfirm: () => {
                const filteredChoices = choices.filter(
                  (c) => c._id !== data.key,
                );
                setChoices(filteredChoices);
              },
            });
            return choice;

          case ACTION_KEYS.EDIT: {
            handleOnClickRow(data);
            return choice;
          }
          default:
            return choice;
        }
      }
      return choice;
    });

    setChoices(updatedChoices);
  };

  const {
    handleSubmit: handleSubmitGroupForm,
    setValue: setValueGroupForm,
    control: controlGroupForm,
    getValues: getValuesGroupForm,
    formState: { errors: errorsGroupForm, isSubmitting: isSubmittingGroupForm },
  } = useForm<IChoiceGroupsForm>({
    resolver: yupResolver(choiceGroupValidationSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      status: ENTITY_STATUS.AVAILABLE,
      minQty: 1,
      maxQty: 1,
      isRequired: true,
    },
  });

  const onSubmit = handleSubmitGroupForm(async () => {
    if (formattedChoiceItems.length === 0) {
      openSnackbar('Please add at least one choice item.', SNACKBARTYPE.ERROR);
      return;
    }

    const requestBody = {
      name: getValuesGroupForm('name'),
      min:
        getValuesGroupForm('minQty') !== -1
          ? getValuesGroupForm('minQty')
          : undefined,
      max:
        getValuesGroupForm('maxQty') !== -1
          ? getValuesGroupForm('maxQty')
          : undefined,
      choices: choices,
      description: getValuesGroupForm('description'),
      status: getValuesGroupForm('status'),
    };

    const response = await dispatch(createChoiceGroupData(requestBody));

    if (response.meta.requestStatus === 'fulfilled') {
      openSnackbar('Choice group created successfully!', SNACKBARTYPE.SUCCESS);
      navigate('/menu/choices');
    }
  });

  const {
    handleSubmit: handleSubmitItemForm,
    reset,
    control: controlItemForm,
    getValues: getValuesItemForm,
    formState: { errors: errorsItemForm, isValid: isValidItemForm },
  } = useForm<IChoiceItemForm>({
    resolver: yupResolver(choiceItemValidationSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const onItemSubmit = handleSubmitItemForm(() => {
    if (!isValidItemForm) {
      return;
    }

    const newItem = {
      _id: Date.now().toString(),
      status: ENTITY_STATUS.AVAILABLE,
      createdAt: new Date(),
      updatedAt: new Date(),
      restaurant: 0,
      tags: [],
      name: getValuesItemForm('name'),
      price: getValuesItemForm('price')
        ? decimalCalculations(
            String(getValuesItemForm('price')).replace(/,/g, ''),
          )
            .toCents()
            .toNumber()
        : 0,
      sequenceNumber: choices.length + 1,
    };

    setChoices((prevChoices) => [...prevChoices, newItem]);

    setValueGroupForm('minQty', 1);
    setValueGroupForm('maxQty', 1);

    reset({
      name: '',
      price: 0,
    });
  });

  const handleIsRequiredChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    const isRequired = value === 'true';

    setValueGroupForm('isRequired', isRequired);
    setValueGroupForm('minQty', isRequired ? 1 : -1);
    setValueGroupForm('maxQty', 1);
  };

  const handleOnClose = () => {
    setIsPopupOpen(false);
  };

  const handleOnClickRow = async (data: IAppTableRow<IChoiceTable>) => {
    const index = choices.findIndex((choice) => choice._id === data.key);
    if (index !== -1) {
      setSelectedChoiceIndex(index);
      setIsPopupOpen(true);
    }
  };

  const handleSaveEdit = (editedChoice: IEditChoiceOnForm) => {
    if (typeof selectedChoiceIndex === 'number') {
      setChoices((prevChoices) => {
        const newChoices = [...prevChoices];
        const currentChoice = newChoices[selectedChoiceIndex];
        newChoices[selectedChoiceIndex] = {
          ...currentChoice,
          name: editedChoice.name,
          price: editedChoice.price ?? currentChoice.price,
        };
        return newChoices;
      });
    }
    setIsPopupOpen(false);
    setSelectedChoiceIndex(null);
  };

  const handleOnRowDrag = async (dragId: string, dropId: string) => {
    const reorderData = reorder<IChoice>({
      list: choices,
      dragId,
      dropId,
    });

    if (!reorderData) {
      return;
    } else {
      setChoices([...reorderData.list]);
    }
  };

  return (
    <Box>
      <HashScroll hashInput="#basic-details">
        <>
          <Controller
            name="name"
            control={controlGroupForm}
            render={({ field: { onBlur, onChange, value } }) => (
              <AppTextInput
                label="Group Name"
                placeholder="Choice Group Name"
                type="text"
                error={errorsGroupForm.name?.message}
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                data-testid="Group Name"
              />
            )}
          />

          <Controller
            name="description"
            control={controlGroupForm}
            render={({ field: { onBlur, onChange, value } }) => (
              <AppTextInput
                showOptional
                label="Description"
                placeholder="Describe your choice"
                type="text"
                error={errorsGroupForm.description?.message}
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                data-testid="description"
              />
            )}
          />

          <Grid container>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={controlGroupForm}
                render={({ field: { onBlur, onChange, value } }) => (
                  <AppSelector
                    label="Status"
                    placeholder="Available"
                    onBlur={onBlur}
                    error={errorsGroupForm.status?.message}
                    value={value}
                    onChange={onChange}>
                    <MenuItem value={ENTITY_STATUS.AVAILABLE}>
                      Available
                    </MenuItem>
                    <MenuItem value={ENTITY_STATUS.UNAVAILABLE}>
                      Unavailable
                    </MenuItem>
                    <MenuItem value={ENTITY_STATUS.HIDDEN}>Hidden</MenuItem>
                  </AppSelector>
                )}
              />
            </Grid>

            <Divider sx={{ marginTop: '25px', marginBottom: '15px' }} />
          </Grid>
        </>
      </HashScroll>

      <Divider />
      <HashScroll hashInput="#add-choices">
        <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
          Add Choice Items
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Controller
            name="name"
            control={controlItemForm}
            render={({ field: { onBlur, onChange, value } }) => (
              <AppTextInput
                label="Choice Item"
                placeholder="Choice Item"
                type="text"
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                error={errorsItemForm.name?.message}
                data-testid="name"
              />
            )}
          />

          <Controller
            name="price"
            control={controlItemForm}
            render={({ field: { onBlur, onChange, value } }) => (
              <AppCurrencyInput
                showOptional
                label="Price"
                value={value}
                onChange={onChange}
                placeholder="0.00"
                onBlur={onBlur}
                error={errorsItemForm.price?.message}
                prefix={restaurant?.posCurrencySymbol}
                data-testid="price"
                sx={{
                  ml: 2,
                }}
                ref={priceInputRef}
              />
            )}
          />

          <AppButton
            title="Add"
            disabled={!getValuesItemForm('name')}
            variant="contained"
            sx={{
              ml: 2,
            }}
            onClick={() => {
              onItemSubmit();
            }}
          />
        </Box>

        {choices.length > 0 ? (
          <>
            <AppTable
              columns={CHOICE_ITEMS_COLUMNS}
              rows={formattedChoiceItems}
              enableRowActions
              enablePagination={false}
              onClickActions={handleOnClickAction}
              onClickRow={handleOnClickRow}
              emptyComponent={<AppEmptyTable title="No Items Found" />}
              onDragDropChange={handleOnRowDrag}
            />
            <EditChoicePopup
              editData={
                selectedChoiceIndex !== null
                  ? choices[selectedChoiceIndex]
                  : undefined
              }
              open={isPopupOpen}
              onClose={handleOnClose}
              onSave={handleSaveEdit as (editedChoice: IChoiceItemForm) => void}
            />
          </>
        ) : null}
      </HashScroll>

      <Divider sx={{ my: 3 }} />

      <Grid container>
        <Grid item xs={7}>
          <Controller
            name="isRequired"
            control={controlGroupForm}
            render={({ field: { onBlur, value } }) => (
              <AppRadioGroup
                label="Required?"
                onBlur={onBlur}
                value={value}
                onChange={(event) => {
                  handleIsRequiredChange(event);
                }}
                error={errorsGroupForm.isRequired?.message}
                row
                disabled={choices.length < 1}>
                <AppRadioGroup.Radio
                  value={true}
                  label="Yes, Mandatory"
                  disabled={choices.length < 1}
                />
                <AppRadioGroup.Radio
                  value={false}
                  label="No, Optional"
                  disabled={choices.length < 1}
                />
              </AppRadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={5}>
          <Stack direction="row">
            <Controller
              name="minQty"
              control={controlGroupForm}
              render={({ field: { onBlur, onChange, value } }) => (
                <AppSelector
                  label="Minimum Choices"
                  placeholder="-"
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  error={errorsGroupForm.minQty?.message}
                  disabled={
                    !getValuesGroupForm('isRequired') || choices.length < 1
                  }>
                  {!getValuesGroupForm('isRequired') ? (
                    <MenuItem value={-1}>-</MenuItem>
                  ) : null}
                  {qtyArray.map((item) => (
                    <MenuItem key={item.toString()} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </AppSelector>
              )}
            />

            <Controller
              name="maxQty"
              control={controlGroupForm}
              render={({ field: { onBlur, onChange, value } }) => (
                <AppSelector
                  label="Maximum Choices"
                  placeholder="-"
                  onBlur={onBlur}
                  value={value}
                  onChange={onChange}
                  error={errorsGroupForm.maxQty?.message}
                  disabled={choices.length < 1}
                  sx={{
                    ml: 2,
                  }}>
                  {qtyArray.map((item) => (
                    <MenuItem key={item.toString()} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </AppSelector>
              )}
            />
          </Stack>
        </Grid>
      </Grid>

      <Stack direction="row-reverse" sx={{ mt: 2, mb: 4 }}>
        <AppButton
          size="large"
          disabled={choices.length <= 0 || isSubmittingGroupForm}
          loading={isSubmittingGroupForm}
          title="Create"
          variant="contained"
          onClick={() => {
            onSubmit();
          }}
          sx={{
            ml: 2,
          }}
        />

        <AppButton
          size="large"
          title="Cancel"
          color="secondary"
          variant="contained"
          onClick={handleOnCancel}
        />
      </Stack>
    </Box>
  );
};

export default CreateChoiceGroupView;
