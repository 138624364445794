import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useLocation } from 'react-router-dom';

import AppVerticalTabs, {
  IVerticalTabItem,
} from '../../../components/AppVerticalTabs';
import { useTabNavigation } from '../../../hooks';
import { CREATE_CHOICE_NAVIGATION_TABS } from '../constants';

const EditChoicesGroupTabs = () => {
  const location = useLocation();

  const EDIT_CHOICES_GROUP_TABS: IVerticalTabItem[] = [
    {
      id: CREATE_CHOICE_NAVIGATION_TABS.BASIC_DETAILS,
      label: 'Basic Details',
      Icon: RestaurantIcon,
      path: `${location.pathname}#basic-details`,
    },
    {
      id: CREATE_CHOICE_NAVIGATION_TABS.ADD_CHOICE_ITEMS,
      label: 'Add Choice Items',
      Icon: RestaurantIcon,
      path: `${location.pathname}#add-choices`,
    },
  ];

  const { currentTab, handleTabChange } = useTabNavigation(
    EDIT_CHOICES_GROUP_TABS,
    CREATE_CHOICE_NAVIGATION_TABS.BASIC_DETAILS,
  );

  return (
    <AppVerticalTabs
      tabs={EDIT_CHOICES_GROUP_TABS}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default EditChoicesGroupTabs;
