import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import * as yup from 'yup';

import AppCurrencyInput from '../../../components/AppCurrencyInput';
import AppPopup, { IDialogAction } from '../../../components/AppPopup';
import AppTextInput from '../../../components/AppTextInput';
import { useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { decimalCalculations } from '../../../utils';

type Props = {
  editData?: IEditChoiceOnForm;
  open: boolean;
  onClose: () => void;
  onSave?: (editedAddOn: IEditChoiceOnForm) => void;
};

export type IEditChoiceOnForm = {
  name: string;
  price?: number;
};

const validationSchema = yup.object({
  name: yup
    .string()
    .max(60, 'Character limit exceeded (60)')
    .required('Choice Item is required'),
  price: yup
    .mixed({ type: 'string|number' })
    .optional()
    .transform((value) => {
      if (!value) {
        return undefined;
      }
      return parseFloat(String(value).replace(/,/g, ''));
    }) as unknown as yup.ISchema<number>,
});

const EditChoicePopup = ({ open, onClose, editData, onSave }: Props) => {
  const { restaurant } = useAppSelector(selectAuth);

  const priceInputRef = useRef<HTMLInputElement>();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    reset,
  } = useForm<IEditChoiceOnForm>({
    resolver: yupResolver(validationSchema) as Resolver<IEditChoiceOnForm>,
  });

  const onSubmit = handleSubmit((values) => {
    onSave?.({
      name: values.name,
      price: values.price
        ? decimalCalculations(String(values.price).replace(/,/g, ''))
            .toCents()
            .toNumber()
        : 0,
    });
  });

  useEffect(() => {
    if (editData) {
      setValue('name', editData.name);
      setValue(
        'price',
        editData.price
          ? decimalCalculations(String(editData.price).replace(/,/g, ''))
              .toDecimal()
              .toNumber()
          : 0.0,
      );
    }
  }, [editData, setValue]);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  const actions: IDialogAction[] = [
    {
      key: 'CANCEL',
      title: 'Cancel',
      onClick: () => {
        reset();
        onClose();
      },
      color: 'secondary',
    },
    {
      key: 'SUBMIT',
      title: 'Save',
      onClick: onSubmit,
      disabled: isSubmitting,
      color: 'primary',
    },
  ];

  return (
    <AppPopup
      open={open}
      onClose={onClose}
      actions={actions}
      title="Edit Choice">
      <form onSubmit={onSubmit}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <AppTextInput
              label="Choice Item"
              placeholder="Choice Item"
              {...field}
              sx={{ marginBottom: '5px' }}
              error={errors.name?.message}
            />
          )}
        />

        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <AppCurrencyInput
              showOptional
              label="Price"
              placeholder="0.00"
              {...field}
              error={errors.price?.message}
              prefix={restaurant?.posCurrencySymbol}
              ref={priceInputRef}
              data-testid="price"
              sx={{
                ml: 0,
              }}
            />
          )}
        />
      </form>
    </AppPopup>
  );
};

export default EditChoicePopup;
