import TabPageLayout from '../../../hocs/AppPageLayout';
import CreateChoiceGroupsTabs from '../components/CreateChoiceGroupsTabs';
import CreateChoiceGroupView from '../views/CreateChoiceGroupView';

const breadCrumbs = [
  {
    title: 'Menu',
    link: '/menu',
  },
  {
    title: 'Choices',
    link: '/menu/choices',
  },
  {
    title: 'Create Choices',
    link: '/menu/choices/create',
  },
];

const CreateChoicesPage = () => {
  return (
    <TabPageLayout
      title="Create Choices"
      TabComponent={CreateChoiceGroupsTabs}
      ContentComponent={CreateChoiceGroupView}
      breadCrumbs={breadCrumbs}
      titleVariant="h5"
    />
  );
};

export default CreateChoicesPage;
