import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

import { Colors } from '../theme/colors';

type AppRadioGroupProps = {
  label?: string;
  showOptional?: boolean;
  labelRightComponent?: ReactNode;
  defaultValue?: unknown;
  children?: ReactNode;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
} & RadioGroupProps;

const AppRadioGroup = ({
  label,
  showOptional,
  labelRightComponent,
  defaultValue,
  children,
  touched,
  error,
  disabled,
  ...props
}: AppRadioGroupProps) => {
  return (
    <FormControl data-testid="app-radio-group">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '5px',
        }}>
        {label && (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              opacity: disabled ? 0.5 : 1,
            }}>
            {label}
            {showOptional ? (
              <span
                style={{
                  fontStyle: 'italic',
                  marginLeft: '4px',
                  marginRight: '4px',
                  fontWeight: 400,
                }}>
                - Optional
              </span>
            ) : null}
          </Typography>
        )}
        {labelRightComponent}
      </Box>
      <RadioGroup defaultValue={defaultValue} {...props}>
        {children}
      </RadioGroup>
      <Box
        sx={{
          height: '25px',
          marginTop: '-2px',
        }}>
        <Fade in={touched && !!error}>
          {touched && !!error ? (
            <Typography
              variant="caption"
              sx={{
                color: Colors.error,
                marginLeft: '10px',
                paddingTop: '5px',
                display: 'flex',
                lineHeight: 'normal',
              }}>
              {error}
            </Typography>
          ) : (
            <Box />
          )}
        </Fade>
      </Box>
    </FormControl>
  );
};

type AppRadioProps = Omit<FormControlLabelProps, 'control'> & {
  disabled?: boolean;
};

const AppRadio = ({ disabled, ...props }: AppRadioProps) => (
  <FormControlLabel {...props} control={<Radio disabled={disabled} />} />
);

AppRadioGroup.Radio = AppRadio;

const AppCustomRadio = ({ disabled, label, ...props }: AppRadioProps) => (
  <FormControlLabel
    {...props}
    label={label}
    control={
      <Radio
        disabled={disabled}
        sx={{
          display: 'none',
        }}
      />
    }
  />
);

AppRadioGroup.CustomRadio = AppCustomRadio;

export default AppRadioGroup;
