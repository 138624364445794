import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import AppVerticalTabs, {
  IVerticalTabItem,
} from '../../../components/AppVerticalTabs';
import { useTabNavigation } from '../../../hooks';
import { CREATE_CHOICE_NAVIGATION_TABS } from '../constants';

const CreateChoiceGroupsTabs = () => {
  const location = useLocation();

  const CREATE_CHOICE_GROUP_TABS: IVerticalTabItem[] = useMemo(
    () => [
      {
        id: CREATE_CHOICE_NAVIGATION_TABS.BASIC_DETAILS,
        label: 'Basic Details',
        Icon: RestaurantIcon,
        path: `${location.pathname}#basic-details`,
      },
      {
        id: CREATE_CHOICE_NAVIGATION_TABS.ADD_CHOICE_ITEMS,
        label: 'Add Choice Items',
        Icon: RestaurantIcon,
        path: `${location.pathname}#add-choices`,
      },
    ],
    [location],
  );

  const { currentTab, handleTabChange } = useTabNavigation(
    CREATE_CHOICE_GROUP_TABS,
    CREATE_CHOICE_NAVIGATION_TABS.BASIC_DETAILS,
  );

  return (
    <AppVerticalTabs
      tabs={CREATE_CHOICE_GROUP_TABS}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default CreateChoiceGroupsTabs;
