import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Brunch from '../../../assets/svgs/brunch.svg';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppEmptyTable from '../../../components/AppEmptyTable';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTable from '../../../components/AppTable';
import { IAppTableRow } from '../../../components/AppTable/types/table.types';
import {
  useAppDispatch,
  useAppSelector,
  usePaginationState,
} from '../../../hooks';
import { selectChoiceGroups } from '../../../redux/selectors/choiceGroupsSelectors';
import { ACTION_KEYS, CHOICE_GROUPS_COLUMNS } from '../constants';
import ViewLayout from '../hocs/ViewLayout';
import {
  deleteChoiceGroupData,
  fetchChoiceGroupData,
  patchUpdateChoiceGroupData,
  updatedChoiceSequenceNumbers,
} from '../redux/choiceGroupSlice';
import {
  disableMenuPagination,
  setMenuPaginationLimit,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import { IChoiceGroupsTable } from '../types/choice-groups.types';
import { ENTITY_STATUS } from '../types/common.types';
import { getFormattedChoiceGroups } from '../utils/formatting.utils';

const ChoicesPage = () => {
  const { openConfirmationPopup } = useConfirmationPopup();
  const dispatch = useAppDispatch();
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { choiceGroupsData, refreshing, extras } =
    useAppSelector(selectChoiceGroups);

  const [isShowAll, setIsShowAll] = useState(false);

  const { onChange, pageIndex, pageSize, total } = usePaginationState({
    total: extras.total,
    pageSize: extras.limit ?? 10,
    pageIndex:
      (extras.skip ?? 0) > 0
        ? Math.floor((extras.skip ?? 0) / (extras.limit ?? 0))
        : 0,
  });

  const handleOnCreate = () => {
    navigate('/menu/choices/create');
  };

  const fetchChoiceGroupDataCallback = useCallback(async () => {
    if (isShowAll) {
      dispatch(disableMenuPagination());
    } else {
      dispatch(setMenuPaginationSkip(pageIndex * pageSize));
      dispatch(setMenuPaginationLimit(pageSize));
    }
    await dispatch(
      fetchChoiceGroupData({ sort_by: 'sequenceNumber', sort_order: 'asc' }),
    );
  }, [dispatch, isShowAll, pageIndex, pageSize]);

  useEffect(() => {
    fetchChoiceGroupDataCallback();
  }, [fetchChoiceGroupDataCallback]);

  const formattedChoiceGroupData = useMemo(() => {
    return choiceGroupsData.map(getFormattedChoiceGroups);
  }, [choiceGroupsData]);

  const handleOnRowDrag = async (dragId: string, dropId: string) => {
    await dispatch(
      updatedChoiceSequenceNumbers({
        dragId,
        dropId,
      }),
    );
  };

  const handlePreviousClick = () => onChange(pageIndex - 1);
  const handleNextClick = () => onChange(pageIndex + 1);

  const handleOnClickRow = useCallback(
    async (data: IAppTableRow<IChoiceGroupsTable>) => {
      navigate(`/menu/choices/edit/${data.key}`);
    },
    [navigate],
  );

  const debouncedHandleOnClickAction = useMemo(
    () =>
      debounce(
        async (key: string, data: IAppTableRow<IChoiceGroupsTable>) => {
          const foundItem = choiceGroupsData.find(
            (item) => item._id === data.key,
          );

          switch (key) {
            case ACTION_KEYS.AVAILABLE: {
              const response = await dispatch(
                patchUpdateChoiceGroupData({
                  _id: data.key,
                  data: {
                    status: ENTITY_STATUS.AVAILABLE,
                  },
                }),
              );
              if (response.meta.requestStatus === 'fulfilled') {
                openSnackbar(
                  'Choice Group status has been updated Successfully!',
                  SNACKBARTYPE.SUCCESS,
                );
              }
              await fetchChoiceGroupDataCallback();
              break;
            }
            case ACTION_KEYS.DELETE: {
              openConfirmationPopup({
                color: 'error',
                heading: `Delete ${foundItem?.name}`,
                content: (
                  <Box>
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {foundItem?.name}
                    </Typography>
                    {
                      ' will no longer be in the choice will be deleted permanently'
                    }
                  </Box>
                ),
                confirmButtonTitle: 'Delete',
                onConfirm: async () => {
                  const response = await dispatch(
                    deleteChoiceGroupData(data.key),
                  );
                  if (response.meta.requestStatus === 'fulfilled') {
                    openSnackbar(
                      'Choice Group has been deleted Successfully!',
                      SNACKBARTYPE.SUCCESS,
                    );
                  }
                  await fetchChoiceGroupDataCallback();
                  onChange(0);
                },
              });
              break;
            }
            case ACTION_KEYS.HIDE: {
              openConfirmationPopup({
                heading: `Hide ${foundItem?.name}`,
                content: (
                  <Box>
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {foundItem?.name}
                    </Typography>
                    {' will be hidden from the choice'}
                  </Box>
                ),
                confirmButtonTitle: 'Hide',
                onConfirm: async () => {
                  const response = await dispatch(
                    patchUpdateChoiceGroupData({
                      _id: data.key,
                      data: {
                        status: ENTITY_STATUS.HIDDEN,
                      },
                    }),
                  );
                  if (response.meta.requestStatus === 'fulfilled') {
                    openSnackbar(
                      'Choice Group has been hidden Successfully!',
                      SNACKBARTYPE.SUCCESS,
                    );
                  }
                  await fetchChoiceGroupDataCallback();
                },
              });
              break;
            }
            case ACTION_KEYS.UNAVAILABLE: {
              openConfirmationPopup({
                heading: `Unavailable ${foundItem?.name}`,
                content: (
                  <Box>
                    <Typography component="span" sx={{ fontWeight: 600 }}>
                      {foundItem?.name}
                    </Typography>
                    {
                      ' will be Unavailable and will no longer have access in the choice items'
                    }
                  </Box>
                ),
                confirmButtonTitle: 'Unavailable',
                onConfirm: async () => {
                  const response = await dispatch(
                    patchUpdateChoiceGroupData({
                      _id: data.key,
                      data: {
                        status: ENTITY_STATUS.UNAVAILABLE,
                      },
                    }),
                  );
                  if (response.meta.requestStatus === 'fulfilled') {
                    openSnackbar(
                      'Choice Group status has been updated to Unavailable Successfully!',
                      SNACKBARTYPE.SUCCESS,
                    );
                  }
                  await fetchChoiceGroupDataCallback();
                },
              });
              break;
            }
            case ACTION_KEYS.EDIT:
              handleOnClickRow(data);
              break;
            default:
              break;
          }
        },
        500,
        { leading: true, trailing: false },
      ),
    [
      dispatch,
      choiceGroupsData,
      fetchChoiceGroupDataCallback,
      openSnackbar,
      openConfirmationPopup,
      handleOnClickRow,
      onChange,
    ],
  );

  const handleShowAllResultsClick = () => {
    if (isShowAll) {
      dispatch(disableMenuPagination());
    }
    setIsShowAll((prevState) => !prevState);
  };

  return (
    <ViewLayout
      title="Choices"
      onClickCreate={handleOnCreate}
      buttonText="Create Choices"
      hideButton={formattedChoiceGroupData.length === 0}>
      <AppTable
        refreshing={refreshing}
        handleNextClick={handleNextClick}
        handlePreviousClick={handlePreviousClick}
        enablePagination={false}
        pagination={{
          total: total,
          pageIndex: pageIndex,
          pageSize: pageSize,
          showAllStatus: isShowAll,
        }}
        columns={CHOICE_GROUPS_COLUMNS}
        rows={formattedChoiceGroupData}
        enableRowActions
        onClickActions={debouncedHandleOnClickAction}
        onClickRow={handleOnClickRow}
        emptyComponent={
          <AppEmptyTable
            title="No Choices Added"
            icon={<img src={Brunch} />}
            primaryButton={{
              title: 'Create Choice',
              startIcon: <AddIcon />,
              onClick: handleOnCreate,
            }}
          />
        }
        onDragDropChange={handleOnRowDrag}
        enableShowAllResultsButton
        handleShowAllResultsClick={handleShowAllResultsClick}
      />
    </ViewLayout>
  );
};

export default ChoicesPage;
